<template>
  <div class="page">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="list-content"
          v-for="(item, index) in listData"
          :key="index"
        >
          <!-- <div class="list-sidebar">
            <img :src="item.activityPicture" alt="" />
          </div> -->
          <div class="list-wrapper">
            <div class="wrap-title">{{ item.applicantsName || "--" }}</div>
            <div class="wrap-content">
              <div class="wrap-content_sex">
                <span>{{ sexMap[item.applicantsSex] }}</span>
              </div>
              <div class="wrap-content_phone">
                <span>{{ item.applicantsPhone }}</span>
              </div>
            </div>
          </div>
          <div class="list-btn" @click="deleUser(item)">
            <span>删除</span>
          </div>
        </div>
      </v-list>
    </v-refresh>
    <v-dialog v-model="isDialog" title="确认取消" @confirm="confirm">
      <!-- <v-input
        ref="vanField"
        v-model="form.remark"
        rows="5"
        type="textarea"
        :maxlength="100"
        placeholder="请输入取消原因"
        show-word-limit
      /> -->
      <p style="text-align: center; line-height: 40px">
        是否确认取消该参与人？
      </p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getAtivityApplicantsListURL,
  getCancelActivityApplicationsByAdmin,
} from "./api.js";

export default {
  name: "activityUserList",
  data() {
    return {
      activityId: "",
      isLoading: false,
      finished: false,
      finishedText: "没有更多了",
      listData: [],
      curPage: 1,
      pageSize: 10,
      isDialog: false,
      form: {
        applicantsId: "", // 参与人id
        userId: "",
      },
      sexMap: {
        "-1": "未知",
        1: "男",
        2: "女",
      },
    };
  },
  watch: {
    isDialog(val) {
      if (!val) {
        this.form = {
          applicantsId: "",
          userId: "",
        };
      }
    },
  },
  created() {
    this.activityId = this.$route.query.activityId;
  },
  methods: {
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getAtivityApplicantsListURL, {
          params: {
            activityId: this.activityId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          this.finishedText = "没有更多了";
          if (res.code === 200 && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.activityId);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.activityId) || this.listData.push(item);
              });
            }
            this.curPage++;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    deleUser(item) {
      console.log(item);
      this.form.applicantsId = item.applicantsId;
      this.form.userId = item.userId;
      this.isDialog = true;
    },
    confirm() {
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "请求中...",
      });
      this.$axios
        .post(getCancelActivityApplicationsByAdmin, null, {
          params: {
            activityId: this.activityId,
            applicantsId: this.form.applicantsId,
            userId: this.form.userId,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.isDialog = false;
            this.$toast.clear();
            this.onRefresh();
            return;
          }
          this.$toast(res.msg || "服务器错误");
        })
        .catch((res) => {
          this.$toast(res.msg || "服务器错误");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 32px;
  .list-content {
    display: flex;
    padding: 24px 32px;
    &:not(:first-child) {
      margin: 32px 0px 0px 0px;
    }
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 10%);
    .list-wrapper {
      flex: 1;
      width: calc(100% - 180px);
      .wrap-title {
        font-size: 36px;
        width: 100%;
        margin-bottom: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .wrap-content {
        font-size: 28px;
        color: #999;
        display: flex;
        line-height: 48px;
        > div {
          span {
            vertical-align: middle;
          }
          margin-right: 20px;
        }
      }
    }
    .list-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      letter-spacing: 4px;
      min-width: 72px;
      > span {
        color: #409eff;
      }
    }
  }
}
</style>
