//获取活动列表
const getActivityListUrl = `/activityApi/api/activity/activityList`;
//获取参与人列表
const getAtivityApplicantsListURL =
  "/activityApi/api/activity/activityApplicantsList";

//运营人员删除活动参与人
const getCancelActivityApplicationsByAdmin = `/activityApi/api/activity/cancelActivityApplicationsByAdmin`;
export {
  getActivityListUrl,
  getAtivityApplicantsListURL,
  getCancelActivityApplicationsByAdmin,
};
